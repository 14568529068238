<template>
    <div class="w-100 d-flex flex-column align-items-center" id="selects-container">
        <!-- Upper Row with ShipTo Address -->
        <b-row class="justify-content-center align-items-center my-2 w-100" id="selects-shipto-row">
            <b-col cols="12" class="px-0 mb-3 mb-md-0 p-0 w-100" id="selects-shipto-col">
                <label for="shipTo" id="selects-shipto-label">{{ t('shipTo') }}</label>
                <b-form-select
                    id="shipTo"
                    class="form-control w-100"
                    v-model="localShipTo"
                    :options="shipOptionsData"
                    :disabled="!selectedFile || singleShipOption"
                    @change="handleShipToChange"
                ></b-form-select>
            </b-col>
        </b-row>
        <!-- Bottom Row with Ship Mode, Axis Tolerance, and PO Number -->
        <b-row class="justify-content-center align-items-center my-2 w-100" id="selects-bottom-row">
            <!-- Ship Mode Column -->
            <b-col
                cols="12"
                md="4"
                class="text-left pl-0 pr-md-4 mb-3 mb-md-0 p-0"
                id="selects-shipmode-col"
            >
                <label for="shipMode">{{ t('shippingMethod') }}</label>
                <b-form-select
                    id="shipMode"
                    class="form-control"
                    v-model="localShipMode"
                    :options="shipModeOptions"
                    :disabled="!selectedFile"
                    @change="handleShipModeChange"
                ></b-form-select>
            </b-col>
            <!-- Axis Tolerance Column -->
            <b-col
                cols="12"
                md="4"
                class="text-left mb-3 mb-md-0 p-0"
                id="selects-axistolerance-col"
            >
                <label for="axisTolerance">{{ t('axisTolerance') }}</label>
                <b-form-select
                    id="axisTolerance"
                    class="form-control"
                    v-model="localAxisTolerance"
                    :options="toleranceOptions"
                    :disabled="localShipMode === -1"
                    @change="handleAxisToleranceChange"
                ></b-form-select>
            </b-col>
            <!-- PO Number Column -->
            <b-col cols="12" md="4" class="pr-0 pl-0 pl-md-4 p-0" id="selects-ponumber-col">
                <label for="poNumber">{{ t('POnumber') }}</label>
                <b-form-input
                    type="text"
                    id="poNumber"
                    v-model="localPONumber"
                    class="form-control"
                    @input="handlePONumberChange"
                ></b-form-input>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: 'Selects',
    props: {
        axisTolerance: Number,
        toleranceOptions: Array,
        shipTo: Number,
        shipOptionsData: Array,
        PO_number: String,
        singleShipOption: Boolean,
        selectedFile: [File, Boolean],
        shipModeOptions: Array,
    },
    data() {
        return {
            localAxisTolerance: this.axisTolerance,
            localShipTo: this.defaultShipTo,
            localPONumber: this.PO_number,
            localShipMode: this.shipModeOptions[0] ? this.shipModeOptions[0].value : null,
        };
    },
    computed: {
        defaultShipTo() {
            const primaryAddress = this.shipOptionsData.find((option) => option.primaryAddress);
            return primaryAddress ? primaryAddress.value : this.localShipTo;
        },
    },
    watch: {
        shipOptionsData: {
            immediate: true,
            handler(newVal) {
                const primaryAddress = newVal.find((option) => option.primaryAddress);
                if (primaryAddress) {
                    this.localShipTo = primaryAddress.value;
                    this.$emit('update-shipTo', primaryAddress.value);
                    const selectedAddress = this.shipOptionsData.find(
                        (option) => option.value === primaryAddress.value
                    );
                    if (selectedAddress) {
                        this.$emit('set-country-code', selectedAddress.countryCode);
                    }
                }
            },
        },
        axisTolerance(newVal) {
            this.localAxisTolerance = newVal;
        },
        shipTo(newVal) {
            this.localShipTo = newVal;
        },
        PO_number(newVal) {
            this.localPONumber = newVal;
        },
        shipModeOptions: {
            immediate: true,
            handler(newVal) {
                if (newVal && newVal.length > 0) {
                    const defaultMethod = newVal.find(
                        (option) => option.text === this.$parent.defaultShippingMethod
                    );
                    this.localShipMode = defaultMethod ? defaultMethod.value : newVal[0].value;
                    this.$emit('update-shipMode', this.localShipMode);
                }
            },
        },
    },
    methods: {
        handleAxisToleranceChange() {
            this.$emit('update-axisTolerance', this.localAxisTolerance);
        },
        handleShipToChange() {
            this.$emit('update-shipTo', this.localShipTo);
            const selectedAddress = this.shipOptionsData.find(
                (option) => option.value === this.localShipTo
            );
            if (selectedAddress) {
                this.$emit('set-country-code', selectedAddress.countryCode);
            }
        },
        handlePONumberChange() {
            this.$emit('update-PO_number', this.localPONumber);
            this.$store.commit('orders/setPOnumb', this.localPONumber);
        },
        handleShipModeChange() {
            this.$emit('update-shipMode', this.localShipMode);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/_variables.scss';
.labelSelect {
    color: $gray-darker;
    margin-top: 20px;
    margin-bottom: 2px;
    margin-left: 4px;
}
</style>
