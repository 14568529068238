<template>
    <b-container
        fluid
        class="flex d-flex justify-content-center flex-column align-items-center"
        id="confirm-container"
    >
        <h2 class="text-primary" id="confirm-title">{{ t('weReceivedFile') }}</h2>
        <p id="confirm-message">{{ t('weWillNotify') }}</p>

        <b-card class="w-100 mt-3 card-details">
            <h3 class="text-primary mb-4">{{ t('checkout_OrderDetails') }}</h3>
            <b-row class="mb-3">
                <b-col sm="4" xl="3">{{ t('referenceNumber') }}: </b-col>
                <b-col sm="8" xl="9">
                    <b>{{ this.bulkOrderResults.results.bulkOrderId }}</b>
                </b-col>
            </b-row>
            <b-row class="mb-3">
                <b-col sm="4" xl="3">{{ t('checkout_OrderDate') }}: </b-col>
                <b-col sm="8" xl="9">
                    <b>{{ currentDate }}</b>
                </b-col>
            </b-row>
            <b-row class="mb-3">
                <b-col sm="4" xl="3">{{ t('customer') }}: </b-col>
                <b-col sm="8" xl="9">
                    <b>{{ formattedCustomerName }}</b>
                    <span class="text-gray-dark"> ({{ formattedCustomerNumber }})</span>
                </b-col>
            </b-row>
            <b-row class="mb-3">
                <b-col sm="4" xl="3">{{ t('statusNormalCase') }}: </b-col>
                <b-col sm="8" xl="9">
                    <b>{{ t('submitted') }}</b>
                </b-col>
            </b-row>
            <b-row class="mb-3">
                <b-col sm="4" xl="3" class="capitalize">{{ t('POnumber') }}: </b-col>
                <b-col sm="8" xl="9">
                    <b>{{ getPOnumb }}</b>
                </b-col>
            </b-row>
        </b-card>
        <b-button
            @click="downloadFile"
            variant="primary"
            id="confirm-place-order-button"
            class="mt-5"
        >
            <b-icon-download class="mr-3" id="confirm-icon-download" />
            {{ t('downloadCopy') }}
        </b-button>
    </b-container>
</template>

<script>
import {mapState, mapGetters} from 'vuex';
import date from '@/filters/date';
export default {
    name: 'Confirm',
    props: {
        finalFilename: {
            type: String,
            required: true,
        },
        bulkOrderResults: {
            type: Object,
            required: true,
        },
        blob: {
            type: Blob,
            required: true,
        },
    },
    computed: {
        ...mapState({
            activeCustomer: (state) => state.user.activeCustomer,
        }),
        ...mapGetters({
            getPOnumb: 'orders/getPOnumb',
            activeCustomerOcosNumberOrId: 'user/activeCustomerOcosNumberOrId',
        }),
        formattedCustomerName() {
            return this.activeCustomer?.name ? this.activeCustomer.name.slice(3) : '';
        },
        formattedCustomerNumber() {
            return this.activeCustomerOcosNumberOrId
                ? this.activeCustomerOcosNumberOrId.slice(3)
                : '';
        },
        currentDate() {
            const laDate = new Date();
            return date(laDate);
        },
    },
    methods: {
        downloadFile() {
            const url = window.URL.createObjectURL(this.blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', this.finalFilename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
    },
};
</script>

<style scoped>
.card-details {
    max-width: 800px !important;
    border-top: 1px lightgray solid;
}
.capitalize {
    text-transform: capitalize;
}
</style>
