<template>
    <div class="d-flex flex-column align-items-center w-100 mt-5" id="file-input-container">
        <b-form-group
            class="w-100"
            :description="`${t('nameOfFile')}: ${fileName}`"
            id="file-input-form-group"
        >
            <b-form-file
                ref="fileInput"
                :placeholder="t('chooseFile')"
                class="bulk-file-input w-100"
                @change="handleFileChange"
                :key="fileInputKey"
                accept=".xlsx"
                :disabled="fetchErrorOccurred"
                id="file-input"
            ></b-form-file>
        </b-form-group>
    </div>
</template>

<script>
export default {
    name: 'FileInput',
    props: {
        fileInputKey: Number,
        fetchErrorOccurred: Boolean,
    },
    data() {
        return {
            fileName: this.$t('noFileSelected'),
        };
    },
    methods: {
        handleFileChange(event) {
            const files = event.target.files;
            if (files.length > 0) {
                const file = files[0];
                if (file.name.endsWith('.xlsx')) {
                    this.fileName = file.name;
                    this.$emit('file-selected', file);
                } else {
                    this.fileName = this.$t('noFileSelected');
                    this.$bvModal.show('file-type-modal');
                }
            } else {
                this.fileName = this.$t('noFileSelected');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/_variables.scss';

.bulk-file-input {
    width: 100%;
}
::v-deep .bulk-file-input .custom-file-input {
    height: 100%;
}
::v-deep .bulk-file-input .custom-file-label {
    padding: 0.5rem 1.5rem;
}
</style>
