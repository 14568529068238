<template>
    <div id="bulk-modal-container">
        <div v-if="localModalVisible" class="blur-overlay" id="bulk-modal-blur-overlay"></div>
        <b-modal
            id="bulk-modal"
            :title="modalTitle"
            hide-footer
            centered
            @hidden="cancelAction"
            v-model="localModalVisible"
        >
            <div class="text-center mt-4" id="bulk-modal-message" v-html="message"></div>

            <div class="d-flex mt-4 justify-content-end" id="bulk-modal-button-container">
                <b-button variant="secondary" @click="cancelAction" id="bulk-modal-cancel-button">
                    {{ computedCancelButtonText }}
                </b-button>
                <b-button
                    class="ml-4"
                    variant="primary"
                    @click="confirmAction"
                    id="bulk-modal-confirm-button"
                >
                    {{ computedConfirmButtonText }}
                </b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: 'BulkModal',
    props: {
        isModalVisible: Boolean,
        modalTitle: {
            type: String,
            default: '',
        },
        message: {
            type: String,
            default: '',
        },
        confirmButtonText: {
            type: String,
            default: '',
        },
        cancelButtonText: {
            type: String,
            default: '',
        },
    },
    computed: {
        localModalVisible: {
            get() {
                return this.isModalVisible;
            },
            set(value) {
                if (!value) {
                    this.cancelAction();
                }
            },
        },
        computedConfirmButtonText() {
            return this.confirmButtonText || this.$t('yes');
        },
        computedCancelButtonText() {
            return this.cancelButtonText || this.$t('no');
        },
    },
    methods: {
        confirmAction() {
            this.$emit('confirm');
        },
        cancelAction() {
            this.$emit('cancel');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/_variables.scss';

.blur-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
}

::v-deep .modal-header {
    background-color: $primary-dark;
    color: white;
}

::v-deep .modal-header .close {
    color: white;
}
</style>
