<template>
    <div class="d-flex justify-content-center flex-column h-100" id="rejected-checks-container">
        <div
            @mouseleave="handleMouseLeave"
            :style="tableStyle"
            id="rejected-checks-table-container"
        >
            <b-table
                :fields="shortenCategory"
                :items="paginatedItems"
                responsive
                striped
                hover
                class="custom-table"
                @row-hovered="handleRowMouseOver"
                @sort-changed="onSortChanged"
                id="rejected-checks-table"
                no-local-sorting
            >
            </b-table>
        </div>

        <b-row id="rejected-checks-below-table" class="width-table">
            <b-col
                class="col-12 d-flex flex-column justify-content-center align-items-center col-md-6 align-items-md-start order-2 order-md-1"
                id="rejected-checks-pagination-container"
            >
                <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    id="rejected-checks-pagination"
                ></b-pagination>
            </b-col>
            <b-col
                class="d-flex col-12 my-4 info-hover col-md-6 justify-content-center justify-content-md-end order-1 order-md-2"
                id="rejected-checks-hover-line"
            >
                {{ t('hoverOverLine') }}
            </b-col>

            <b-col class="col-12 order-3 order-md-3">
                <h4
                    class="text-primary text-center mt-3 w-100"
                    id="rejected-checks-all-lenses-excluded"
                >
                    {{ t('allLensesExcluded') }}
                </h4>
            </b-col>
        </b-row>

        <div class="hover-text-container" v-if="hoverTextVisible" id="rejected-checks-hover-text">
            <h4><b-icon-exclamation-triangle class="mr-3" />{{ hoverTitle }}</h4>
            <p v-html="hoverParagraph"></p>
        </div>
        <div style="height: 86.25px" v-else id="rejected-checks-placeholder"></div>
    </div>
</template>

<script>
import {regulatoryMixin} from '../regulatoryMixin.js';
export default {
    mixins: [regulatoryMixin],
    props: {
        rejectedChecks: Array,
        countryCodeAddress: String,
    },
    data() {
        return {
            defaultCountryCode: 'US',
            items: this.rejectedChecks,
            fields: [
                {key: 'Sales Category', sortable: true},
                {key: 'Description', label: this.$t('description_label'), sortable: true},
                {key: 'Diopter', label: this.$t('sphere_label'), sortable: true},
                {key: 'Cylinder', label: this.$t('cylinder_label'), sortable: true},
                {key: 'Axis', label: this.$t('axis_label'), sortable: true},
                {key: 'Quantity', label: this.$t('quantity_label'), sortable: true},
                {key: 'Reason', label: this.$t('reasonofrejection'), sortable: true},
            ],
            hoverTextVisible: false,
            hoverTitle: '',
            hoverParagraph: '',
            currentPage: 1,
            perPage: 100,
            Reasons: {
                generic: {
                    reason: this.$t('item_label'),
                    short: this.$t('invalidLicense'),
                    long: {
                        generic: this.$t('orderNotAllowed'),
                    },
                },
                sphere: {
                    reason: this.$t('sphere_label'),
                    short: this.$t('invalidSphereLicense'),
                    long: {
                        generic: this.$t('sphereNotAllowed'),
                        CA:
                            this.$t('sphereNotAllowed') +
                            ' ' +
                            this.$t('orderThroughStandardAndGov'),
                        JA: this.$t('sphereNotAllowed') + ' ' + this.$t('orderThroughStandard'),
                    },
                },
                cylinder: {
                    reason: this.$t('cylinder_label'),
                    short: this.$t('invalidCylinderLicense'),
                    long: {
                        generic: this.$t('cylinderNotAllowed'),
                        CA:
                            this.$t('cylinderNotAllowed') +
                            ' ' +
                            this.$t('orderThroughStandardAndGov'),
                        JA: this.$t('cylinderNotAllowed') + ' ' + this.$t('orderThroughStandard'),
                    },
                },
                sphereCylinder: {
                    reason: this.$t('sphereCylinder'),
                    short: this.$t('invalidShpereAndCylinder'),
                    long: {
                        generic: this.$t('orderNotAllowedForShpereCylinder'),
                        CA:
                            this.$t('orderNotAllowedForShpereCylinder') +
                            ' ' +
                            this.$t('orderThroughStandardAndGov'),
                        JA:
                            this.$t('orderNotAllowedForShpereCylinder') +
                            ' ' +
                            this.$t('orderThroughStandard'),
                    },
                },
                notSellable: {
                    reason: this.$t('notSellable'),
                    short: this.$t('notSellableShort'),
                    long: {
                        generic: this.$t('notSellableLong'),
                    },
                },
                noRightsforSpherical: {
                    reason: this.$t('spherical_label'),
                    short: this.$t('noRightsSpherical'),
                    long: {
                        generic: this.$t('rightsToricOnly'),
                    },
                },
                noRightsforToric: {
                    reason: this.$t('toric_label'),
                    short: this.$t('noRightsToric'),
                    long: {
                        generic: this.$t('rightsSphericalOnly'),
                    },
                },
            },
        };
    },
    computed: {
        countryCode() {
            return this.countryCodeAddress || this.defaultCountryCode;
        },
        sortedItems() {
            const itemsFailed = this.items.map((item) => {
                const {diopterValue, cylinderValue, cleanedDescription} =
                    this.getFormattedValues(item);
                const reason = this.getItemReason(item.errors);

                return {
                    'Sales Category': item.salesCategory.value,
                    Description: cleanedDescription,
                    Diopter: diopterValue,
                    Cylinder: cylinderValue,
                    Axis: item.axis,
                    Quantity: item.quantity,
                    Reason: this.getReasonText(reason),
                };
            });

            return this.sortItems(itemsFailed);
        },

        tableStyle() {
            return this.getTableStyle(this.rejectedChecks, 12);
        },
        rows() {
            return this.rejectedChecks.length;
        },
    },
    methods: {
        getItemReason(errors) {
            if (errors.includes(4)) {
                return 'notSellable';
            }
            if (errors.includes(7)) {
                return 'noRightsforSpherical';
            }
            if (errors.includes(6)) {
                return 'noRightsforToric';
            }
            if (errors.includes(1) && errors.includes(2)) {
                return 'sphereCylinder';
            }
            if (errors.includes(1)) {
                return 'cylinder';
            }
            if (errors.includes(2)) {
                return 'sphere';
            }
            return 'generic';
        },
        handleMouseLeave() {
            this.hoverTextVisible = false;
        },
        handleRowMouseOver(item) {
            if (item) {
                const reasonKey = Object.keys(this.Reasons).find(
                    (key) => this.Reasons[key].reason === item.Reason
                );
                if (reasonKey) {
                    this.hoverTitle = this.Reasons[reasonKey].short;
                    this.hoverParagraph =
                        this.Reasons[reasonKey].long[this.countryCode] ||
                        this.Reasons[reasonKey].long.generic;
                    this.hoverTextVisible = true;
                } else {
                    this.hoverTextVisible = false;
                }
            } else {
                this.hoverTextVisible = false;
            }
        },
        getReasonText(param) {
            return this.Reasons[param].reason;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/_variables.scss';

::v-deep .custom-table .table th,
::v-deep .custom-table .table td {
    width: 171.5px !important;
}
::v-deep .custom-table .table th {
    height: 50px !important;
}
.width-table {
    max-width: 1230px;
}
.info-hover {
    font-size: 14px;
    color: $gray-dark;
    font-style: italic;
}
.hover-text-container {
    text-align: left;
    width: 100%;
    background-color: $warning;
    padding: 15px;
    padding-bottom: 0px;
    border-radius: 6px;
    max-width: 1215px;
}
</style>
