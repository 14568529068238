<template>
    <b-container fluid class="file-upload-container" id="file-upload-container">
        <b-row class="my-5" id="file-upload-row">
            <b-col class="d-flex flex-column align-items-center" id="file-upload-col">
                <div class="text-title" id="file-upload-title">
                    {{ t('bulkOrderFor') }} -
                    <b style="color: black" id="file-upload-customer-info">
                        {{ activeCustomer.ocosCustomerNumber }} - {{ activeCustomer.name }}
                    </b>
                </div>
                <div class="keep-it-centered w-100" id="file-upload-content">
                    <GuideTemplateRow
                        @show-toast="showToastMessage"
                        id="file-upload-guide-template-row"
                    />
                    <FileInput
                        :fileInputKey="fileInputKey"
                        :fetchErrorOccurred="fetchErrorOccurred"
                        @file-selected="updateFileName"
                        id="file-upload-file-input"
                    />
                    <Selects
                        :axisTolerance="localAxisTolerance"
                        :toleranceOptions="toleranceOptions"
                        :shipTo="localShipTo"
                        :shipOptionsData="shipOptionsData"
                        :PO_number="PO_number"
                        :singleShipOption="singleShipOption"
                        :selectedFile="!!selectedFile"
                        :shipModeOptions="shipModes"
                        @update-axisTolerance="updateAxisTolerance"
                        @update-shipTo="updateShipTo"
                        @update-PO_number="updatePONumber"
                        @update-shipMode="updateShipMode"
                        @set-country-code="updateCountryCode"
                        id="file-upload-selects"
                    />
                    <Notes :notes.sync="notes" id="file-upload-notes" />
                    <b-button
                        variant="primary"
                        class="d-flex align-items-center mt-4 px-5"
                        :disabled="isButtonDisabled"
                        @click="processFile"
                        id="file-upload-button"
                    >
                        {{ t('processFile') }}
                        <template v-if="isProcessing">
                            <b-spinner small class="ml-3" id="file-upload-spinner" />
                        </template>
                        <template v-else>
                            <b-icon-upload class="ml-3" id="file-upload-icon" />
                        </template>
                    </b-button>
                </div>
            </b-col>
        </b-row>
        <BulkModal
            :isModalVisible="isModalVisible"
            @show="handleModalShow"
            @hidden="handleModalHidden"
            @hide="hideModal"
            id="file-upload-modal"
        />
        <ToastBulk
            v-if="showToast"
            :description="customToastErrors[0].description"
            @close="hideToast"
            id="file-upload-toast"
            :countryCode="customerCountry"
            :showSupportEmail="showSupportEmail"
        />
    </b-container>
</template>

<script>
import {mapState} from 'vuex';
import GuideTemplateRow from './components/GuideTemplateRow.vue';
import FileInput from './components/FileInput.vue';
import Notes from './components/Notes.vue';
import Selects from './components/Selects.vue';
import BulkModal from './components/BulkModal.vue';
import ToastBulk from '../components/ToastBulk.vue';

export default {
    name: 'FileUpload',
    components: {
        GuideTemplateRow,
        Notes,
        FileInput,
        Selects,
        BulkModal,
        ToastBulk,
    },
    props: {
        axisTolerance: Number,
        toleranceOptions: Array,
        shipTo: Number,
        shipOptions: Array,
    },
    data() {
        return {
            selectedFile: null,
            fileName: this.$t('noFileSelected'),
            notes: '',
            PO_number: '',
            fileInputKey: 0,
            isProcessing: false,
            isModalVisible: false,
            fetchError: '',
            shipOptionsData: [],
            customToastErrors: [],
            showToast: false,
            fetchErrorOccurred: false,
            singleShipOption: false,
            localAxisTolerance: this.axisTolerance,
            localShipTo: this.shipTo,
            customerSiteDetailId: null,
            countryCode: null,
            shipModes: [],
            localShipMode: -1,
            responseObject: null,
            defaultShippingMethod: null,
            customerCountry: 'US',
            showSupportEmail: false,
        };
    },
    methods: {
        processFile() {
            this.setSpinner(true);
            this.isProcessing = true;
            const formData = new FormData();
            formData.append('Attachment', this.selectedFile);
            formData.append('AxisTolerance', this.localAxisTolerance);
            formData.append('PONumber', this.PO_number);
            formData.append('Notes', this.notes);
            formData.append('CustomerSiteDetailId', this.customerSiteDetailId);
            formData.append('CustomerId', this.activeCustomer.customerId);
            formData.append('CountryCode', this.countryCode);
            formData.append('ShippingMethodId', this.localShipMode);
            this.$http
                .post(`bulkorders/submit-file`, formData)
                .then((response) => {
                    this.responseObject = response.data;
                    if (!response.data.success) {
                        this.customToastErrors = [
                            {
                                key: 'fetch-error',
                                description: this.$t('excelStructureError'),
                            },
                        ];
                        this.showToast = true;
                    } else {
                        this.$emit('bulk-order-results', this.responseObject);
                    }
                })
                .catch((error) => {
                    this.customToastErrors = [
                        {
                            key: 'fetch-error',
                            description: this.$t('connErrorUpload') + this.$t('pleaseTryLater'),
                        },
                    ];
                    this.showToast = true;
                    this.fetchErrorOccurred = true;
                })
                .finally(() => {
                    this.isProcessing = false;
                    this.setSpinner(false);
                });
        },
        updateFileName(file) {
            if (file) {
                this.selectedFile = file;
                this.fileName = file.name;
            } else {
                this.selectedFile = null;
                this.fileName = this.$t('noFileSelected');
                this.$bvModal.show('file-type-modal');
            }
        },
        updateCountryCode(code) {
            this.countryCode = code;
            this.$emit('set-country-code', code);
        },
        updateAxisTolerance(value) {
            this.localAxisTolerance = value;
        },
        updateShipMode(value) {
            this.localShipMode = value;
        },
        updateShipTo(value) {
            this.localShipTo = value;
            const selectedAddress = this.shipOptionsData.find((option) => option.value === value);
            if (selectedAddress) {
                this.customerSiteDetailId = selectedAddress.customerSiteDetailId;
                this.countryCode = selectedAddress.countryCode;
            }
        },
        updatePONumber(value) {
            this.PO_number = value;
        },
        handleModalShow() {
            this.isModalVisible = true;
        },
        handleModalHidden() {
            this.isModalVisible = false;
            this.resetFileInput();
        },
        hideModal() {
            this.$bvModal.hide('file-type-modal');
        },
        hideToast() {
            this.showToast = false;
        },
        showToastMessage(errorData) {
            this.customToastErrors = [errorData];
            this.showToast = true;
        },
        resetFileInput() {
            this.$nextTick(() => {
                this.fileInputKey++;
            });
            this.selectedFile = null;
            this.fileName = this.$t('noFileSelected');
        },
        fetchShipOptions() {
            const customerId = this.activeCustomer.customerId;
            this.$http
                .get(`customers/${customerId}/addresses/shipping`)
                .then((response) => {
                    this.fetchError = '';
                    const results = response.data.results;
                    if (results.length === 0) {
                        throw new Error(this.$t('shipAddressNotFound'));
                    } else {
                        this.shipOptionsData = results.map((item) => ({
                            value: item.customerSiteDetailId,
                            text: item.address,
                            customerSiteDetailId: item.customerSiteDetailId,
                            countryCode: item.country,
                            primaryAddress: item.isPrimaryShipTo,
                        }));
                        if (results.length === 1) {
                            this.customerSiteDetailId = results[0].customerSiteDetailId;
                            this.singleShipOption = true;
                            this.updateShipTo(results[0].customerSiteDetailId);
                        } else {
                            this.shipOptionsData.unshift({
                                value: -1,
                                text: this.$t('select').toLowerCase(),
                            });
                        }
                    }
                })
                .catch((error) => {
                    this.fetchError =
                        this.$t('failedRetrieveShipping') + this.$t('pleaseContactSupport');
                    this.customToastErrors = [
                        {
                            key: 'fetch-error',
                            description: this.fetchError,
                        },
                    ];
                    this.showToast = true;
                    this.showSupportEmail = true;
                    this.fetchErrorOccurred = true;
                });
        },

        fetchShipModes() {
            this.$http
                .get(`shoppingcart/shippingmethods`)
                .then((response) => {
                    this.fetchError = '';
                    let results = response.data.results;
                    if (results.length === 0) {
                        throw new Error(this.$t('failedRetrieveMethods'));
                    } else {
                        results = results.sort((a, b) => {
                            const textA =
                                `${a.carrierName}-${a.serviceLevel}-${a.modeOfTransport}`.toLowerCase();
                            const textB =
                                `${b.carrierName}-${b.serviceLevel}-${b.modeOfTransport}`.toLowerCase();
                            return textA.localeCompare(textB);
                        });
                        this.shipModes = results.map((item) => ({
                            value: item.shippingMethodId,
                            text: `${item.carrierName}-${item.serviceLevel}-${item.modeOfTransport}`,
                        }));
                        if (results.length > 1) {
                            this.shipModes.unshift({
                                value: -1,
                                text: this.$t('select').toLowerCase(),
                            });
                        }
                        this.updateShipMode(results[0].shippingMethodId);
                    }
                })
                .catch((error) => {
                    this.fetchError =
                        this.$t('failedRetrieveMethods') + this.$t('pleaseContactSupport');
                    this.customToastErrors = [
                        {
                            key: 'fetch-error',
                            description: this.fetchError,
                        },
                    ];
                    this.showToast = true;
                    this.showSupportEmail = true;
                    this.fetchErrorOccurred = true;
                });
        },
    },
    computed: {
        isButtonDisabled() {
            const isFileXlsx = this.selectedFile && this.selectedFile.name.endsWith('.xlsx');
            return (
                !isFileXlsx ||
                this.localShipTo === -1 ||
                this.PO_number === '' ||
                this.localAxisTolerance === -1 ||
                this.localShipMode === -1
            );
        },
        ...mapState({
            activeCustomer: (state) => state.user.activeCustomer,
        }),
    },
    watch: {
        axisTolerance(newVal) {
            this.localAxisTolerance = newVal;
        },
        shipTo(newVal) {
            this.localShipTo = newVal;
        },
    },
    created() {
        this.showSupportEmail = false;
        this.defaultShippingMethod = this.activeCustomer.defaultShippingMethod;
        this.fetchShipModes();
        this.fetchShipOptions();
        this.customerCountry = this.activeCustomer.ocosCustomerNumber.substring(0, 2);
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/_variables.scss';

.file-upload-container {
    min-height: 92vh;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.title {
    color: $gray-darker;
    margin-bottom: 1rem;
    text-align: center;
}

.keep-it-centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 95%;
    margin: auto;
}

@media (min-width: map-get($grid-breakpoints, xl)) {
    .keep-it-centered {
        max-width: 80%;
    }
}

@media (max-width: map-get($grid-breakpoints, xl)) {
    .keep-it-centered {
        max-width: 100%;
    }
}

@media (min-width: 1100px) {
    .keep-it-centered {
        max-width: 800px;
    }
}
</style>
