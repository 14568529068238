<template>
    <div class="custom-toast" id="toast-bulk-container">
        <b-icon-exclamation-octagon-fill
            class="mr-3"
            font-scale="2.5"
            id="toast-bulk-icon-warning"
        />
        <div id="toast-bulk-content">
            <h5 class="heavy mb-1" id="toast-bulk-title">{{ t('error') }}</h5>
            <slot id="toast-bulk-slot">{{ description }}</slot>
            <a
                v-if="showSupportEmail"
                :href="`mailto:${supportEmail}`"
                id="toast-bulk-support-email"
                class="support-email"
            >
                {{ supportEmail }}
            </a>
        </div>
        <b-icon-x class="close-icon" @click="$emit('close')" id="toast-bulk-icon-close"></b-icon-x>
    </div>
</template>

<script>
export default {
    name: 'ToastBulk',
    props: {
        description: {
            type: String,
            required: true,
        },
        countryCode: {
            type: String,
            required: true,
        },
        showSupportEmail: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        supportEmail() {
            return this.countryCode === 'US'
                ? 'customerservice@staar.com'
                : 'customerservice.ag@staar.com';
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/_variables.scss';

.custom-toast {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 99;
    background-color: $error-dark;
    color: white;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    align-items: start;
    max-width: 300px;
}

.close-icon {
    cursor: pointer;
    font-size: 1.5rem;
}

.support-email {
    color: white;
    text-decoration: underline;
    cursor: pointer;
}
</style>
