<template>
    <div :style="tableStyle" id="passed-checks-container">
        <b-table
            :fields="shortenCategory"
            :items="paginatedItems"
            striped
            responsive
            hover
            class="custom-table"
            @sort-changed="onSortChanged"
            id="passed-checks-table"
            no-local-sorting
        >
            <template #cell(Price)="data">
                <div :id="'passed-checks-price-' + data.index">
                    {{ data.item.Price | toCurrency }}
                </div>
            </template>
            <template #cell(TotalPrice)="data">
                <div :id="'passed-checks-total-price-' + data.index">
                    {{ data.item['TotalPrice'] | toCurrency }}
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
import {regulatoryMixin} from '../regulatoryMixin.js';
export default {
    mixins: [regulatoryMixin],
    props: ['passedChecks', 'currentPage', 'perPage'],
    data() {
        return {
            items: this.passedChecks,
            fields: [
                {key: 'Sales Category', sortable: true},
                {key: 'Description', label: this.$t('description_label'), sortable: true},
                {key: 'Diopter', label: this.$t('sphere_label'), sortable: true},
                {key: 'Cylinder', label: this.$t('cylinder_label'), sortable: true},
                {key: 'Axis', label: this.$t('axis_label'), sortable: true},
                {key: 'Price', label: this.$t('checkout_Price'), sortable: true},
                {key: 'Quantity', label: this.$t('quantity_label'), sortable: true},
                {
                    key: 'TotalPrice',
                    label: this.$t('subtotal'),
                    sortable: true,
                    tdClass: 'font-weight-bold',
                },
            ],
        };
    },
    computed: {
        sortedItems() {
            const itemsWithTotal = this.items.map((item) => {
                const {diopterValue, cylinderValue, cleanedDescription} =
                    this.getFormattedValues(item);
                return {
                    'Sales Category': item.salesCategory.value,
                    Description: cleanedDescription,
                    Diopter: diopterValue,
                    Cylinder: cylinderValue,
                    Axis: item.axis,
                    Price: item.price,
                    Quantity: item.quantity,
                    TotalPrice: item.totalPrice,
                };
            });
            this.$emit(
                'update-total-sum',
                itemsWithTotal.reduce((sum, item) => sum + item['TotalPrice'], 0)
            );
            return this.sortItems(itemsWithTotal);
        },
        tableStyle() {
            return this.getTableStyle(this.items, 14);
        },
    },
};
</script>

<style scoped>
::v-deep .custom-table .table th,
::v-deep .custom-table .table td {
    width: 150px !important;
}

::v-deep .custom-table .table th {
    height: 50px !important;
}

::v-deep .custom-table .table td.font-weight-bold {
    font-weight: bold;
}
</style>
