<template>
    <b-container fluid class="flex d-flex justify-content-center" id="errors-review-container">
        <b-row class="d-flex flex-column align-items-center adjust" id="errors-review-row">
            <h4 class="my-4 text-center" id="errors-review-title">
                <b-icon-exclamation-triangle class="mr-3" id="errors-review-icon-warning" />{{
                    t('correctHighlighted')
                }}
            </h4>
            <b-col class="d-flex flex-column w-100" id="errors-review-col">
                <b-row class="mb-1 no-gutters flex-grow-0" id="errors-review-subrow">
                    <b-col
                        cols="12"
                        lg="4"
                        class="order-lg-1 order-3 d-flex justify-content-lg-start justify-content-center"
                        id="errors-review-left-col"
                    >
                        <h5
                            :class="{
                                'no-errors': rowsWithErrorCount === 0,
                                'text-danger': rowsWithErrorCount > 0,
                            }"
                            class="mb-0"
                            id="errors-review-error-count"
                        >
                            <template
                                v-if="rowsWithErrorCount > 0"
                                id="errors-review-icon-template"
                            >
                                <b-icon-exclamation-circle
                                    class="mr-1"
                                    id="errors-review-icon-error"
                                />
                            </template>
                            {{ rowsWithErrorCount }} {{ t('rowsErrors') }}
                        </h5>
                    </b-col>
                    <b-col
                        cols="12"
                        lg="8"
                        class="order-lg-2 order-1 d-flex justify-content-lg-end justify-content-center"
                        id="errors-review-right-col"
                    >
                        <h5 id="errors-review-customer-info">
                            <span class="greytext text-gray mr-2" id="errors-review-customer-label"
                                >{{ t('customerAccount') }}:</span
                            >{{ activeCustomer.ocosCustomerNumber }} - {{ activeCustomer.name }}
                        </h5>
                    </b-col>
                </b-row>

                <SpinnerOverlay v-if="!validations" />
                <TableErrorsJV
                    v-else
                    class="flex-grow-1 h-100 overflow-auto"
                    style="max-height: 40rem"
                    id="errors-review-table-errors"
                    @rows-with-error-count="handleRowsWithErrorCount"
                    :bulkOrderResults="bulkOrderResults"
                    :validations="validations"
                    :validationsStrings="validationsStrings"
                    @update-corrections="updateCorrections"
                />

                <div
                    class="my-2 flex-grow-0 d-flex justify-content-center align-items-start"
                    id="errors-review-button-container"
                >
                    <b-button
                        variant="primary"
                        class="d-flex align-items-center px-5 my-4"
                        :disabled="isButtonDisabled"
                        @click="reviewFile"
                        id="errors-review-button"
                    >
                        <div id="errors-review-button-text">
                            {{ t('reviewFile') }}<b-icon-arrow-repeat class="ml-2" />
                        </div>
                    </b-button>
                </div>
            </b-col>
        </b-row>
        <ToastBulk
            v-if="showToast"
            :description="customToastErrors[0].description"
            @close="hideToast"
            id="errors-review-toast"
        />
    </b-container>
</template>

<script>
import {mapState} from 'vuex';
import TableErrorsJV from './components/TableErrorsJV.vue';
import ToastBulk from '../components/ToastBulk.vue';
import SpinnerOverlay from '@/views/layout/SpinnerOverlay';

export default {
    name: 'ErrorsReview',
    components: {
        TableErrorsJV,
        ToastBulk,
        SpinnerOverlay,
    },
    data() {
        return {
            rowsWithErrorCount: 0,
            validations: null,
            validationsStrings: null,
            corrections: [],
            passedArray: [],
            failedArray: [],
            showToast: false,
        };
    },
    props: {
        bulkOrderResults: {
            type: Object,
            required: true,
        },
    },
    computed: {
        isButtonDisabled() {
            return this.rowsWithErrorCount > 0;
        },
        ...mapState({
            activeCustomer: (state) => state.user.activeCustomer,
        }),
    },
    methods: {
        handleRowsWithErrorCount(count) {
            this.rowsWithErrorCount = count;
        },
        hideToast() {
            this.showToast = false;
        },
        fetchValidations() {
            this.$http
                .get(`bulkorders/validations`)
                .then((response) => {
                    const results = response.data.results;
                    if (results && results.length > 0) {
                        this.validations = results;
                        this.validationsStrings = results.map((validation) => {
                            return {
                                ...validation,
                                sphereDiopters: validation.sphereDiopters.map((value) =>
                                    value % 1 === 0 && value > 0
                                        ? `+${value.toFixed(1)}`
                                        : value % 1 === 0 && value < 0
                                        ? `${value.toFixed(1)}`
                                        : value > 0
                                        ? `+${value}`
                                        : value.toString()
                                ),
                                cylinderDiopters: validation.cylinderDiopters.map((value) =>
                                    value % 1 === 0 && value !== 0
                                        ? value.toFixed(1)
                                        : value.toString()
                                ),
                            };
                        });
                    } else {
                        this.customToastErrors = [
                            {
                                key: 'fetch-error',
                                description: this.$t('noValidations') + this.$t('pleaseTryLater'),
                            },
                        ];
                        this.showToast = true;
                    }
                })
                .catch((error) => {
                    this.customToastErrors = [
                        {
                            key: 'fetch-error',
                            description: this.$t('connErrorValidation'),
                        },
                    ];
                    this.showToast = true;
                });
        },
        reviewFile() {
            this.setSpinner(true);
            const data = this.corrections.map((item) => ({
                bulkOrder: {},
                bulkOrderId: this.bulkOrderResults.results.bulkOrderId,
                bulkOrderDetailId: item.bulkOrderDetailId,
                salesCategory: String(item.salesCategory),
                sphere: String(item.sphere),
                cylinder: item.cylinder == null ? null : String(item.cylinder),
                axis: item.axis == null ? null : String(item.axis),
                quantity: String(item.quantity),
            }));
            this.corrections = data;

            this.$http
                .put(
                    `bulkorders/${this.bulkOrderResults.results.bulkOrderId}/apply-corrections`,
                    data
                )
                .then((response) => {
                    this.passedArray = response.data.results.regulatoryValidationResult.passed;
                    this.failedArray = response.data.results.regulatoryValidationResult.failed;
                    this.$emit('handle-regulatory', this.passedArray, this.failedArray);
                })
                .catch((error) => {
                    this.customToastErrors = [
                        {
                            key: 'fetch-error',
                            description: this.$t('connErrorReview') + this.$t('pleaseTryLater'),
                        },
                    ];
                    this.showToast = true;
                })
                .finally(() => {
                    this.setSpinner(false);
                });
        },
        updateCorrections(items) {
            this.corrections = items.map((item) => ({
                bulkOrderDetailId: item.bulkOrderDetailId,
                salesCategory: String(item.salesCategory),
                sphere: String(item.sphere),
                cylinder: item.cylinder == null ? null : String(item.cylinder),
                axis: item.axis == null ? null : String(item.axis),
                quantity: String(item.quantity),
            }));
        },
    },
    created() {
        this.fetchValidations();
        this.$nextTick(() => {
            this.rowsWithErrorCount = this.bulkOrderResults.results.validationResult.rows.length;
        });
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/_variables.scss';
@media (max-width: 1199.98px) {
    .adjust {
        width: 100% !important;
    }
}
@media (min-width: 1200px) {
    .adjust {
        width: 80% !important;
        max-width: 1200px !important;
    }
}
.no-errors {
    color: $primary-dark;
}
.greytext {
    color: $gray-dark;
}
.edit-top-input {
    padding: 6px;
    border-radius: 4px;
    text-align: center;
    border: 1px solid $gray;
}
h4 {
    font-size: 2rem;
    color: $primary;
    font-weight: 700;
    margin-bottom: 0px;
}
.title {
    font-size: 2rem;
    color: $gray-darker;
    margin-bottom: 1rem;
    text-align: center;
}
</style>
