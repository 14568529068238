<template>
    <div class="table-container custom-scroll" id="table-errorsJV-container">
        <b-table
            :fields="shortenCategory"
            :items="items"
            responsive="sm"
            class="custom-table"
            id="table-errorsJV-table"
        >
            <template v-for="field in fields" v-slot:[`cell(${field.key})`]="data">
                <div
                    :key="`${data.index}-${field.key}`"
                    class="cell-container"
                    :id="`table-errorsJV-cell-container-${data.index}-${field.key}`"
                >
                    <input
                        :id="`${data.index}-${field.key}`"
                        ref="inputField"
                        :value="data.item[field.key]"
                        @input="updateValue($event, data.item, field.key)"
                        class="special-bg cell-input text-center"
                        :class="{invalid: data.item[`${field.key}Valid`] === false}"
                        @mouseover="setOverlayText(data.item, field.key)"
                        @focus="setOverlayText(data.item, field.key)"
                        @blur="validateField(data.item, field.key, data.index)"
                        @keypress.enter="blurInput($event)"
                    />

                    <b-tooltip
                        v-if="
                            data.item[`${field.key}Valid`] === false ||
                            activeTooltipId === `${data.index}-${field.key}`
                        "
                        :target="`${data.index}-${field.key}`"
                        @show="
                            !activeTooltipId
                                ? setActiveTooltip(data, field)
                                : $event.preventDefault()
                        "
                        @hide="activeTooltipId = ''"
                        :id="`table-errorsJV-tooltip-${data.index}-${field.key}`"
                        boundary="window"
                    >
                        <strong>{{ overlayText }}</strong>
                    </b-tooltip>
                </div>
            </template>
            <template v-slot:[`cell(delete)`]="data">
                <div
                    :key="`delete-${data.index}`"
                    class="cell-container cell-input cell-delete h-100"
                    :id="`table-errorsJV-cell-container-delete-${data.index}`"
                >
                    <b-icon-trash
                        @click="handleDelete(data.item)"
                        style="cursor: pointer"
                        id="table-errorsJV-trash-icon"
                    />
                </div>
            </template>
        </b-table>
        <BulkModal
            :isModalVisible="isModalVisible"
            @confirm="confirmDelete"
            @cancel="cancelDelete"
            :modalTitle="$t('removeLenses')"
            :message="$t('removeEntireRow')"
            :confirmButtonText="$t('yes')"
            :cancelButtonText="$t('no')"
        />
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import BulkModal from '../../components/BulkModal.vue';
export default {
    props: {
        bulkOrderResults: {
            type: Object,
            required: true,
        },
        validations: {
            type: Array,
            default: () => [],
        },
        validationsStrings: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        BulkModal,
    },
    data() {
        return {
            items: [],
            fields: [
                {key: 'salesCategory'},
                {key: 'sphere', label: this.$t('sphere_label')},
                {key: 'cylinder', label: this.$t('cylinder_label')},
                {key: 'axis', label: this.$t('axis_label')},
                {key: 'quantity', label: this.$t('quantity_label')},
                {key: 'delete', label: this.$t('checkout_Remove'), thStyle: {width: '75px'}},
            ],
            currentInvalid: false,
            overlayText: '',
            activeTooltipId: '',
            TEST_SEPARATOR: null,
            separator: '',
            isModalVisible: false,
            itemToDelete: null,
            isSmallScreen: window.matchMedia('(max-width: 1240px)').matches,
        };
    },
    watch: {
        bulkOrderResults: {
            handler(newResults) {
                this.items = this.transformBulkOrderResults(newResults);
            },
            immediate: true,
            deep: true,
        },
        activeTooltipId() {},
    },
    computed: {
        ...mapGetters('user', ['currentUser']),
        availableCategories() {
            return this.validations.map((validation) => validation.salesCategory).join(', ');
        },
        shortenCategory() {
            return this.fields.map((field) => {
                if (field.key === 'salesCategory') {
                    field.label = this.isSmallScreen
                        ? this.$t('categoryshort')
                        : this.$t('salesCategory');
                }
                return field;
            });
        },
    },
    methods: {
        handleDelete(item) {
            const index = this.items.indexOf(item);
            if (index > -1) {
                this.itemToDelete = item;
                this.isModalVisible = true;
            }
        },
        confirmDelete() {
            const index = this.items.indexOf(this.itemToDelete);
            if (index > -1) {
                const bulkOrderId = this.bulkOrderResults.results.bulkOrderId;
                const bulkOrderDetailId = this.itemToDelete.bulkOrderDetailId;
                this.setSpinner(true);
                this.$http
                    .delete(`bulkorders/${bulkOrderId}/delete-stage-row/${bulkOrderDetailId}`)
                    .then((response) => {
                        if (response.data.success) {
                            this.$emit('update-corrections', this.items);
                            this.items.splice(index, 1);
                            this.countRowWithErrors();
                        } else {
                            this.showErrorToast(this.$t('failedDelete'));
                        }
                    })
                    .catch((error) => {
                        this.showErrorToast(this.$t('failedDelete'));
                    })
                    .finally(() => {
                        this.setSpinner(false);
                    });
            }
            this.isModalVisible = false;
            this.itemToDelete = null;
        },
        showErrorToast(message) {
            this.$bvToast.toast(message, {
                title: this.$t('error'),
                variant: 'danger',
                autoHideDelay: 3000,
            });
        },
        cancelDelete() {
            this.isModalVisible = false;
            this.itemToDelete = null;
        },
        updateValue(event, item, fieldKey) {
            let inputValue = event.target.value;
            if (fieldKey === 'salesCategory') {
                item[fieldKey] = inputValue;
            } else if (fieldKey === 'axis') {
                const intPattern = /^[0-9]*$/;
                if (intPattern.test(inputValue) || inputValue === '') {
                    item[fieldKey] = inputValue;
                } else {
                    event.target.value = item[fieldKey];
                }
            } else if (fieldKey === 'quantity') {
                const intPattern = /^[1-9]\d*$/;
                if (intPattern.test(inputValue)) {
                    item[fieldKey] = inputValue;
                } else {
                    event.target.value = item[fieldKey];
                }
            } else if (fieldKey === 'cylinder') {
                const floatPattern = /^[0-9]*[.,]?[0-9]*$/;
                if (floatPattern.test(inputValue) || inputValue === '') {
                    item[fieldKey] =
                        this.separator === ',' ? inputValue.replace(',', '.') : inputValue;
                } else {
                    event.target.value = item[fieldKey];
                }
            } else if (fieldKey === 'sphere') {
                const spherePattern = /^[+-]?[0-9]*([.,][0-9]*)?$/;
                if (spherePattern.test(inputValue)) {
                    item[fieldKey] =
                        this.separator === ',' ? inputValue.replace(',', '.') : inputValue;
                } else {
                    event.target.value = item[fieldKey];
                }
            }
        },

        setActiveTooltip(data, field) {
            this.setOverlayText(data.item, field.key);
            this.activeTooltipId = `${data.index}-${field.key}`;
        },
        validateField(item, fieldKey, index) {
            if (fieldKey === 'salesCategory') {
                const availableCategories = this.validations.map((v) => v.salesCategory);
                if (availableCategories.includes(item.salesCategory)) {
                    item.salesCategoryValid = true;
                    if (this.activeTooltipId === `${index}-${fieldKey}`) {
                        this.activeTooltipId = '';
                    }
                    this.revalidateRow(item);
                } else {
                    item.salesCategoryValid = false;
                }
            } else if (fieldKey === 'sphere') {
                const validation = this.validations.find(
                    (v) => v.salesCategory === item.salesCategory
                );
                const floatPattern = /^[+-]?\d*(\.\d+)?$/;
                let sphereValue = item.sphere ? item.sphere.toString() : '';
                if (
                    sphereValue.startsWith('.') ||
                    sphereValue.startsWith('+.') ||
                    sphereValue.startsWith('-.')
                ) {
                    sphereValue = sphereValue
                        .replace(/^\./, '0.')
                        .replace(/^\+\./, '+0.')
                        .replace(/^-\./, '-0.');
                    item.sphere = sphereValue;
                }
                if (floatPattern.test(item.sphere)) {
                    const sphereValue = parseFloat(item.sphere);
                    if (validation.sphereDiopters.includes(sphereValue)) {
                        item.sphereValid = true;
                        if (this.activeTooltipId === `${index}-${fieldKey}`) {
                            this.activeTooltipId = '';
                        }
                    } else {
                        item.sphereValid = false;
                    }
                } else {
                    item.sphereValid = false;
                }
            } else if (fieldKey === 'cylinder') {
                const validation = this.validations.find(
                    (v) => v.salesCategory === item.salesCategory
                );
                if (validation) {
                    if (validation.lensTypeCode === 'TICL') {
                        const floatPattern = /^\d*(\.\d+)?$/;
                        let cylinderValue = item.cylinder ? item.cylinder.toString() : '';
                        if (cylinderValue.startsWith('.')) {
                            cylinderValue = '0' + cylinderValue;
                            item.cylinder = cylinderValue;
                        }

                        if (floatPattern.test(item.cylinder)) {
                            const parsedCylinderValue = parseFloat(item.cylinder);
                            if (validation.cylinderDiopters.includes(parsedCylinderValue)) {
                                item.cylinderValid = true;
                                if (this.activeTooltipId === `${index}-${fieldKey}`) {
                                    this.activeTooltipId = '';
                                }
                            } else {
                                item.cylinderValid = false;
                            }
                        } else {
                            item.cylinderValid = false;
                        }
                    } else if (validation.lensTypeCode === 'ICL') {
                        if (item.cylinder !== '' && item.cylinder !== null) {
                            item.cylinderValid = false;
                        } else {
                            item.cylinderValid = true;
                            if (this.activeTooltipId === `${index}-${fieldKey}`) {
                                this.activeTooltipId = '';
                            }
                        }
                    }
                }
            } else if (fieldKey === 'axis') {
                const validation = this.validations.find(
                    (v) => v.salesCategory === item.salesCategory
                );
                if (validation) {
                    if (validation.lensTypeCode === 'TICL') {
                        const intPattern = /^-?\d+$/;
                        if (intPattern.test(item.axis)) {
                            const axisValue = parseInt(item.axis, 10);
                            if (axisValue < 0 || axisValue > 180 || isNaN(axisValue)) {
                                item.axisValid = false;
                            } else {
                                item.axisValid = true;
                                if (this.activeTooltipId === `${index}-${fieldKey}`) {
                                    this.activeTooltipId = '';
                                }
                            }
                        } else {
                            item.axisValid = false;
                        }
                    } else if (validation.lensTypeCode === 'ICL') {
                        if (item.axis !== '' && item.axis !== null) {
                            item.axisValid = false;
                        } else {
                            item.axisValid = true;
                            if (this.activeTooltipId === `${index}-${fieldKey}`) {
                                this.activeTooltipId = '';
                            }
                        }
                    }
                }
            } else if (fieldKey === 'quantity') {
                const intPattern = /^[1-9]\d*$/;
                if (intPattern.test(item.quantity)) {
                    const quantityValue = parseInt(item.quantity, 10);
                    if (quantityValue > 0) {
                        item.quantityValid = true;
                        if (this.activeTooltipId === `${index}-${fieldKey}`) {
                            this.activeTooltipId = '';
                        }
                    } else {
                        item.quantityValid = false;
                    }
                } else {
                    item.quantityValid = false;
                }
            }
            if (fieldKey === 'sphere') {
                const parsedValue = parseFloat(item[fieldKey]);
                const sign = parsedValue > 0 ? '+' : '';
                if (Number.isInteger(parsedValue) && parsedValue !== 0) {
                    item[fieldKey] = `${sign}${parsedValue.toFixed(1)}`;
                } else {
                    item[fieldKey] = `${sign}${parsedValue}`;
                }
            } else if (fieldKey === 'cylinder') {
                const parsedValue = parseFloat(item[fieldKey]);
                if (Number.isInteger(parsedValue) && parsedValue !== 0) {
                    item[fieldKey] = parsedValue.toFixed(1).toString();
                }
            }
            this.$emit('update-corrections', this.items);
            this.countRowWithErrors();
        },
        revalidateRow(item) {
            const validation = this.validations.find((v) => v.salesCategory === item.salesCategory);
            if (!validation) {
                return;
            }
            if (
                !/^[+-]?\d+(\.\d+)?$/.test(item.sphere) ||
                !validation.sphereDiopters.includes(parseFloat(item.sphere))
            ) {
                item.sphereValid = false;
            } else {
                item.sphereValid = true;
            }
            const cylinderValue = parseFloat(item.cylinder);
            if (validation.lensTypeCode === 'TICL') {
                if (
                    !/^\d+(\.\d+)?$/.test(item.cylinder) ||
                    !validation.cylinderDiopters.includes(cylinderValue)
                ) {
                    item.cylinderValid = false;
                } else {
                    item.cylinderValid = true;
                }
                const axisValue = parseInt(item.axis, 10);
                if (
                    axisValue < 0 ||
                    axisValue > 180 ||
                    isNaN(axisValue) ||
                    !/^\d+$/.test(item.axis)
                ) {
                    item.axisValid = false;
                } else {
                    item.axisValid = true;
                }
            } else if (validation.lensTypeCode === 'ICL') {
                if (item.cylinder !== '' && item.cylinder !== null) {
                    item.cylinderValid = false;
                    item.cylinderInvalidMessage = this.$t('cylinderEmpty');
                } else {
                    item.cylinderValid = true;
                    item.cylinderInvalidMessage = '';
                }
                if (item.axis !== '' && item.axis !== null) {
                    item.axisValid = false;
                    item.axisInvalidMessage = this.$t('axisEmpty');
                } else {
                    item.axisValid = true;
                    item.axisInvalidMessage = '';
                }
            }
            const quantityValue = parseInt(item.quantity, 10);
            if (!Number.isInteger(quantityValue) || quantityValue <= 0) {
                item.quantityValid = false;
            } else {
                item.quantityValid = true;
            }
        },
        countRowWithErrors() {
            let errorCount = 0;
            this.items.forEach((item) => {
                if (
                    !item.salesCategoryValid ||
                    !item.sphereValid ||
                    !item.cylinderValid ||
                    !item.axisValid ||
                    !item.quantityValid
                ) {
                    errorCount++;
                }
            });
            this.$emit('rows-with-error-count', errorCount);
        },
        transformBulkOrderResults(results) {
            if (!results || !results.results || !results.results.validationResult) {
                return [];
            }
            return results.results.validationResult.rows.map((row) => {
                let sphereValue = row.sphere.value;
                let cylinderValue = row.cylinder.value;
                if (sphereValue !== null && Number.isInteger(parseFloat(sphereValue))) {
                    sphereValue = parseFloat(sphereValue).toFixed(1);
                    if (sphereValue > 0) {
                        sphereValue = `+${sphereValue}`;
                    }
                }
                if (cylinderValue !== null && Number.isInteger(parseFloat(cylinderValue))) {
                    cylinderValue = parseFloat(cylinderValue).toFixed(1);
                }
                return {
                    bulkOrderDetailId: row.bulkOrderDetailId,
                    lensTypeCode: row.lensTypeCode,
                    salesCategory: row.salesCategory.value,
                    salesCategoryValid: row.salesCategory.isValid,
                    salesCategoryOriginal: row.salesCategory.originalValue,
                    sphere:
                        row.sphere.isValid === null && row.sphere.value === null
                            ? row.sphere.originalValue
                            : sphereValue,
                    sphereValid: row.sphere.isValid,
                    sphereOriginal: row.sphere.originalValue,
                    cylinder:
                        row.cylinder.isValid === null && row.cylinder.value === null
                            ? row.cylinder.originalValue
                            : cylinderValue,
                    cylinderValid: row.cylinder.isValid,
                    cylinderOriginal: row.cylinder.originalValue,
                    axis:
                        row.axis.isValid === null && row.axis.value === null
                            ? row.axis.originalValue
                            : row.axis.value,
                    axisValid: row.axis.isValid,
                    axisOriginal: row.axis.originalValue,
                    quantity: row.quantity.value,
                    quantityValid: row.quantity.isValid,
                    quantityOriginal: row.quantity.originalValue,
                };
            });
        },
        setOverlayText(item, fieldKey) {
            const replaceDotWithComma = (number) => {
                return number.toString().replace('.', ',');
            };
            if (this.activeTooltipId) {
                return;
            }
            const category = item.salesCategory;
            const validation = this.validationsStrings.find((v) => v.salesCategory === category);
            if (fieldKey === 'salesCategory' && item[`${fieldKey}Valid`] === false) {
                this.overlayText = this.$t('availableCategories') + `: ${this.availableCategories}`;
            } else if (fieldKey === 'sphere' && item[`${fieldKey}Valid`] === false && validation) {
                const formattedSphereDiopters =
                    this.separator === ','
                        ? validation.sphereDiopters.map(replaceDotWithComma).join(', ')
                        : validation.sphereDiopters.join(', ');
                this.overlayText = this.$t('availableSphere') + `: ${formattedSphereDiopters}`;
            } else if (fieldKey === 'cylinder' && item[`${fieldKey}Valid`] === false) {
                const formattedCylinderDiopters =
                    this.separator === ','
                        ? validation.cylinderDiopters.map(replaceDotWithComma).join(', ')
                        : validation.cylinderDiopters.join(', ');
                if (validation && validation.lensTypeCode === 'ICL') {
                    this.overlayText = this.$t('cylinderEmpty');
                } else {
                    this.overlayText =
                        this.$t('availableCylinder') + `: ${formattedCylinderDiopters}`;
                }
            } else if (fieldKey === 'axis' && item[`${fieldKey}Valid`] === false) {
                if (validation && validation.lensTypeCode === 'ICL') {
                    this.overlayText = this.$t('axisEmpty');
                } else {
                    this.overlayText = this.$t('axisValues') + `: 0 ` + this.$t('from_to') + ` 180`;
                }
            } else if (fieldKey === 'quantity' && item[`${fieldKey}Valid`] === false) {
                this.overlayText = this.$t('quantityBiggerThan');
            }
        },
        blurInput(event) {
            event.target.blur();
            this.validateField(event.target, event.target.getAttribute('data-key'));
        },
    },
    created() {
        this.items = this.transformBulkOrderResults(this.bulkOrderResults);
        this.$nextTick(() => {
            this.countRowWithErrors();
        });
    },
    mounted() {
        this.TEST_SEPARATOR !== null
            ? (this.separator = this.TEST_SEPARATOR)
            : (this.separator = this.currentUser.decimalSeparator);
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/_variables.scss';

#table-errorsJV-trash-icon:hover {
    color: $danger;
}
.table-container {
    overflow: visible;
}
.cell-container {
    position: relative;
    overflow: visible;
}
.cell-input {
    border: 1px solid $gray-light;
    border-radius: 0;
    outline: none;
    box-shadow: none;
    width: 100%;
}
// cell-delete class is necessary in firefox only
.cell-delete {
    height: 34px !important;
}
.special-bg:focus {
    background-color: $primary-lighter;
}
.invalid {
    background-color: rgba($danger, 0.3);

    &:focus {
        background-color: rgba($danger, 0.1);
        border: 1px solid $danger;
    }
}

::v-deep .custom-table .table tr {
    border-bottom: none;
    border-top: none;
}

::v-deep .custom-table .table td {
    border-top: none;
    border-bottom: none;
}
::v-deep .custom-table .table thead th {
    text-align: center;
    padding: 2px 8px;
}
</style>
