import {mapGetters} from 'vuex';

export const regulatoryMixin = {
    data() {
        return {
            separator: '',
            TEST_SEPARATOR: null,
            isSmallScreen: window.matchMedia('(max-width: 1240px)').matches,
            currentSortKey: '',
            currentSortOrder: '',
        };
    },
    created() {
        this.separator =
            this.TEST_SEPARATOR !== null ? this.TEST_SEPARATOR : this.currentUser.decimalSeparator;
    },
    methods: {
        getTableStyle(items, maxRowsToShow) {
            const rowHeight = 30;
            const height = items.length > maxRowsToShow ? `${maxRowsToShow * rowHeight}px` : 'auto';
            return {
                maxHeight: height,
                overflowY: 'auto',
                overflowX: 'hidden',
                position: 'relative',
            };
        },
        sortItems(items) {
            if (this.currentSortKey) {
                items.sort((a, b) => {
                    let aValue = a[this.currentSortKey];
                    let bValue = b[this.currentSortKey];
                    if (this.currentSortKey === 'Diopter') {
                        aValue = parseFloat(aValue.replace(',', '.').replace('+', ''));
                        bValue = parseFloat(bValue.replace(',', '.').replace('+', ''));
                        if (this.currentSortOrder === 'asc') {
                            return aValue - bValue;
                        } else {
                            return bValue - aValue;
                        }
                    } else {
                        if (this.currentSortOrder === 'asc') {
                            return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
                        } else {
                            return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
                        }
                    }
                });
            }
            return items;
        },
        getFormattedValues(item) {
            let diopterValue =
                item.sphere.value != null
                    ? (item.sphere.value % 1 === 0
                          ? item.sphere.value.toFixed(1)
                          : item.sphere.value
                      )
                          .toString()
                          .replace(/^\d/, (match) => (item.sphere.value > 0 ? '+' : '') + match)
                    : '';

            let cylinderValue =
                item.cylinder.value != null
                    ? (item.cylinder.value % 1 === 0
                          ? item.cylinder.value.toFixed(1)
                          : item.cylinder.value
                      ).toString()
                    : '';

            if (this.separator === ',') {
                diopterValue = diopterValue.replace('.', ',');
                cylinderValue = cylinderValue.replace('.', ',');
            }

            const cleanedDescription = item.description.replace(/\s*\(.*?\)\s*/g, '').trim();

            return {diopterValue, cylinderValue, cleanedDescription};
        },
        onSortChanged(ctx) {
            this.currentSortKey = ctx.sortBy;
            this.currentSortOrder = ctx.sortDesc ? 'desc' : 'asc';
        },
    },
    computed: {
        ...mapGetters('user', ['currentUser']),
        shortenCategory() {
            return this.fields.map((field) => {
                if (field.key === 'Sales Category') {
                    field.label = this.isSmallScreen
                        ? this.$t('categoryshort')
                        : this.$t('salesCategory');
                }
                return field;
            });
        },
        paginatedItems() {
            const startIndex = (this.currentPage - 1) * this.perPage;
            return this.sortedItems.slice(startIndex, startIndex + this.perPage);
        },
    },
};
