<template>
    <b-row class="justify-content-start align-items-center my-2 w-100" id="guide-template-row">
        <b-button variant="primary" @click="openQuickGuide" id="guide-template-quick-guide-button">
            <b-icon-lightning-fill class="mr-2" id="guide-template-lightning-icon" />{{
                t('quickGuide')
            }}
        </b-button>
        <b-button
            variant="link"
            class="link_download ml-3"
            @click="downloadTemplate"
            id="guide-template-download-button"
        >
            {{ t('exampleXLSXtemplate') }}
            <b-icon-download class="ml-1" id="guide-template-download-icon" />
        </b-button>
    </b-row>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
    name: 'GuideTemplateRow',
    methods: {
        openQuickGuide() {
            const routeData = this.$router.resolve({
                name: 'QuickGuide',
                query: {
                    'account-id': this.activeCustomerOcosNumberOrId,
                },
            });
            window.open(routeData.href, '_blank');
        },
        downloadTemplate() {
            this.$http
                .get(`bulkorders/sample-file`, {
                    responseType: 'blob',
                })
                .then((response) => {
                    const url = window.URL.createObjectURL(
                        new Blob([response.data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        })
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Bulk_order_template.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
                .catch((error) => {
                    this.$emit('show-toast', {
                        key: 'download-error',
                        description: this.$t('connErrorTemplate') + this.$t('pleaseTryLater'),
                    });
                });
        },
    },
    computed: {
        ...mapGetters({
            activeCustomerOcosNumberOrId: 'user/activeCustomerOcosNumberOrId',
        }),
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/_variables.scss';

.link_download {
    color: $primary-dark;
    cursor: pointer;
    font-weight: bold;
}
</style>
