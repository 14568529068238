<template>
    <div id="bulk-modal-container">
        <div v-if="isModalVisible" class="blur-overlay" id="bulk-modal-blur-overlay"></div>
        <b-modal
            id="file-type-modal"
            :title="t('fileTypeError')"
            hide-footer
            centered
            @show="handleModalShow"
            @hidden="handleModalHidden"
        >
            <div class="text-center mt-4" id="bulk-modal-message">{{ t('onlyXLSXfiles') }}</div>
            <div class="d-flex justify-content-end" id="bulk-modal-button-container">
                <b-button
                    variant="primary"
                    class="mt-3"
                    @click="hideModal"
                    id="bulk-modal-button"
                    >{{ t('ok') }}</b-button
                >
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: 'BulkModal',
    props: {
        isModalVisible: Boolean,
    },
    methods: {
        handleModalShow() {
            this.$emit('show');
        },
        handleModalHidden() {
            this.$emit('hidden');
        },
        hideModal() {
            this.$emit('hide');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/_variables.scss';

.blur-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
}

::v-deep .modal-header {
    background-color: $primary-dark;
    color: white;
}

::v-deep .modal-header .close {
    color: white;
}
</style>
