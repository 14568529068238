<template>
    <b-row class="mt-3 w-100" id="notes-row">
        <label for="notes" id="notes-label">{{ t('notes') }}</label>
        <textarea
            id="notes-textarea"
            v-model="localNotes"
            class="form-control w-100"
            rows="4"
            :placeholder="t('enterNotesForUs')"
            maxlength="250"
        ></textarea>
    </b-row>
</template>

<script>
export default {
    name: 'Notes',
    props: {
        notes: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            localNotes: this.notes,
        };
    },
    watch: {
        localNotes(newValue) {
            this.$emit('update:notes', newValue);
        },
    },
};
</script>
