<template>
    <b-container fluid class="flex d-flex justify-content-center" id="regulatory-checks-container">
        <b-row class="d-flex flex-column align-items-center adjust" id="regulatory-checks-row">
            <b-col class="w-100 my-4 px-0 d-flex flex-column" id="regulatory-checks-col">
                <b-tabs
                    v-model="currentTab"
                    pills
                    class="d-flex flex-column flex-grow-1 h-100"
                    id="regulatory-checks-tabs"
                    nav-wrapper-class="custom-pills"
                >
                    <b-tab active id="regulatory-checks-tab-passed" class="scrolls custom-scroll">
                        <template #title>
                            <span class="tabs-title" id="regulatory-checks-passed-title">{{
                                t('passed_regulatory')
                            }}</span>
                        </template>

                        <PassedChecksTable
                            :passed-checks="passed"
                            :current-page="currentPage"
                            :per-page="perPage"
                            @update-total-sum="updateTotalSum"
                            id="regulatory-checks-passed-table"
                        />
                        <h4
                            class="text-primary text-center mt-3 w-100"
                            id="rejected-checks-all-lenses-excluded"
                        >
                            {{ t('allLensesIncluded') }}
                        </h4>
                    </b-tab>
                    <b-tab class="h-100 scrollsh custom-scroll" id="regulatory-checks-tab-failed">
                        <template #title>
                            <span class="tabs-title" id="regulatory-checks-failed-title">
                                {{ t('failed_regulatory', {count: numberOfFailed}) }}
                            </span>
                        </template>
                        <RejectedChecks
                            :rejected-checks="failedChecks"
                            :countryCodeAddress="countryCode"
                            id="regulatory-checks-rejected-checks"
                        />
                    </b-tab>
                </b-tabs>

                <b-row
                    v-if="currentTab === 0"
                    class="mt-5 justify-content-between align-items-center no-gutters"
                    id="regulatory-checks-pagination-row"
                >
                    <!-- Column 1 -->
                    <b-col
                        class="d-flex flex-column justify-content-center align-items-center align-items-md-start m-0 p-0 col-12 col-md-4 order-1 order-md-1"
                        id="regulatory-checks-pagination-col"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="rows"
                            :per-page="perPage"
                            aria-controls="my-table"
                            id="regulatory-checks-pagination"
                            class="m-0 p-0"
                        ></b-pagination>
                    </b-col>

                    <!-- Column 2 (Should appear third on small screens) -->
                    <b-col
                        class="m-0 p-0 d-flex justify-content-center col-12 col-md-4 order-3 order-md-2"
                        id="regulatory-checks-button-col"
                    >
                        <b-button
                            @click="placeOrder"
                            variant="primary"
                            :disabled="!hasNonAccessory"
                            id="regulatory-checks-place-order-button"
                            class="m-0"
                        >
                            <b-icon-clipboard-data
                                class="mr-3"
                                id="regulatory-checks-icon-clipboard"
                            />{{ t('placeOrderDownloadFile') }}
                        </b-button>
                    </b-col>

                    <!-- Column 3 (Should appear second on small screens) -->
                    <b-col
                        class="d-flex justify-content-center justify-content-md-end my-4 m-md-0 p-0 col-12 col-md-4 order-2 order-md-3"
                        id="regulatory-checks-total-col"
                    >
                        <div
                            class="mr-md-5 mr-0"
                            style="font-size: 1.3rem"
                            id="regulatory-checks-total"
                        >
                            {{ t('total') }}:
                            <span style="font-weight: 700" id="regulatory-checks-total-sum">{{
                                totalSum | toCurrency
                            }}</span>
                        </div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <ToastBulk
            v-if="showToast"
            :description="customToastErrors[0].description"
            @close="hideToast"
            id="regulatory-checks-toast"
        />
    </b-container>
</template>

<script>
import PassedChecksTable from './components/PassedChecksTable.vue';
import RejectedChecks from './components/RejectedChecks.vue';
import ToastBulk from '../components/ToastBulk.vue';

export default {
    name: 'RegulatoryChecks',
    components: {
        PassedChecksTable,
        RejectedChecks,
        ToastBulk,
    },
    data() {
        return {
            currentTab: 0,
            currentPage: 1,
            perPage: 100,
            totalSum: 0,
            rows: this.passed.length,
            passedChecks: this.passed,
            failedChecks: this.failed,
            showToast: false,
        };
    },
    props: {
        passed: {
            type: Array,
            required: true,
        },
        failed: {
            type: Array,
            required: true,
        },
        bulkOrderResults: {
            type: Object,
            required: true,
        },
        countryCode: {
            type: String,
            required: true,
        },
        finalFilename: {
            type: String,
            required: true,
        },
    },
    methods: {
        updateTotalSum(total) {
            this.totalSum = total;
        },
        hideToast() {
            this.showToast = false;
        },
        placeOrder() {
            this.setSpinner(true);
            this.$http
                .get(`bulkorders/${this.bulkOrderResults.results.bulkOrderId}/place-order`, {
                    responseType: 'blob',
                })
                .then((response) => {
                    const contentDisposition = response.headers['content-disposition'];
                    const filenameRegex = /filename="(.+?)"/;
                    const matches = filenameRegex.exec(contentDisposition);
                    const filename = matches && matches[1] ? matches[1] : 'bulk_order.xlsx';
                    const blob = new Blob([response.data], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    });
                    this.$emit('update-final-filename', filename);
                    this.$emit('update-blob', blob);
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    this.$emit('go-to-confirmation');
                })
                .catch((error) => {
                    this.customToastErrors = [
                        {
                            key: 'fetch-error',
                            description: this.$t('connErrorPlaceOrder') + this.$t('pleaseTryLater'),
                        },
                    ];
                    this.showToast = true;
                })
                .finally(() => {
                    this.setSpinner(false);
                });
        },
    },
    computed: {
        hasNonAccessory() {
            return this.passed.some((item) => !item.isAccessory);
        },
        numberOfFailed() {
            return Array.isArray(this.failed) ? this.failed.length : 0;
        },
    },
};
</script>

<style scoped>
.scrolls {
    overflow-x: auto;
    width: 100%;
    max-width: 90vw;
    box-sizing: border-box;
}

.scrollsh {
    overflow-x: hidden;
    width: 100%;
    max-width: 90vw;
    box-sizing: border-box;
}

::v-deep .tab-content {
    width: 100%;
}

.tabs-title {
    font-size: 1.5rem;
}

::v-deep .custom-pills .nav-link {
    border-radius: 0;
    padding: 5px 20px 3px 20px;
    margin-bottom: 4px;
}
</style>
