<template>
    <b-container fluid class="bulk-container py-4" id="bulk-container">
        <b-row class="mb-4" id="bulk-row">
            <b-col
                xl="9"
                class="d-flex flex-column justify-content-left flex-sm-row align-items-sm-center"
                id="bulk-col-header"
            >
                <a href="#" @click.prevent="goToStep(0)" class="link-header" id="bulk-link-header">
                    <h1 class="text-primary mb-0" id="bulk-h1-title">{{ t('bulkOrdering') }}</h1>
                </a>
                <h3
                    v-if="currentIndex > 0"
                    class="text-primary ml-3 mb-0 mt-2 mt-sm-0 pt-3"
                    id="bulk-h2-subtitle"
                >
                    {{ subtitle }}
                </h3>
            </b-col>
        </b-row>

        <b-row id="bulk-content-row">
            <b-col id="bulk-content-col">
                <b-card class="d-flex flex-column flex-grow-1" id="bulk-card">
                    <component
                        :is="componentsList[currentIndex]"
                        :axisTolerance="axisTolerance"
                        :toleranceOptions="toleranceOptions"
                        :shipTo="shipTo"
                        :shipOptions="shipOptions"
                        style="min-height: 600px"
                        @changeStep="goToStep"
                        @bulk-order-results="handleBulkOrderResults"
                        :bulk-order-results="bulkOrderResults"
                        :passed="passed"
                        :failed="failed"
                        @handle-regulatory="handleRegulatory"
                        @go-to-confirmation="goToConfirmation"
                        :countryCode="countryCode"
                        @set-country-code="setCountryCode"
                        id="bulk-component"
                        :finalFilename="finalFilename"
                        :blob="blob"
                        @update-final-filename="updateFinalFilename"
                        @update-blob="updateBlob"
                    />
                </b-card>
            </b-col>
        </b-row>

        <p class="font-weight-lighter mt-2 text-center" id="bulk-footer">
            <small class="float-left" id="bulk-disclaimer">{{ t('disclaimer') }}</small>
        </p>
    </b-container>
</template>

<script>
import FileUpload from './fileUpload/FileUpload.vue';
import ErrorsReview from './errorsReview/ErrorsReview.vue';
import RegulatoryChecks from './regulatoryChecks/RegulatoryChecks.vue';
import Confirm from './confirm/Confirm.vue';

export default {
    name: 'Bulk',
    components: {
        FileUpload,
        ErrorsReview,
        RegulatoryChecks,
        Confirm,
    },
    data() {
        return {
            componentsList: [FileUpload, ErrorsReview, RegulatoryChecks, Confirm],
            currentIndex: 0,
            axisTolerance: -1,
            toleranceOptions: [
                {value: -1, text: this.$t('select').toLowerCase()},
                ...Array.from({length: 18}, (_, i) => ({value: i + 5, text: `${i + 5}`})),
            ],
            shipTo: -1,
            shipOptions: [],
            bulkOrderResults: {},
            passed: [],
            failed: [],
            countryCode: '',
            finalFilename: '',
            blob: null,
        };
    },
    computed: {
        subtitle() {
            const subtitles = [
                '',
                '   ' + this.$t('reviewAndCorrectChanges'),
                '   ' + this.$t('regulatoryChecks'),
                '   ' + this.$t('confirmation_title'),
            ];
            return subtitles[this.currentIndex];
        },
    },
    methods: {
        goToStep(index) {
            this.currentIndex = index;
        },
        handleBulkOrderResults(res) {
            this.bulkOrderResults = res;
            if (res.results.validationResult.hasValidationError) {
                this.goToStep(1);
            } else {
                this.handleRegulatory(
                    res.results.regulatoryValidationResult.passed,
                    res.results.regulatoryValidationResult.failed
                );
            }
        },
        handleRegulatory(dataPassed, dataFailed) {
            this.passed = dataPassed;
            this.failed = dataFailed;
            this.goToStep(2);
        },
        goToConfirmation() {
            this.goToStep(3);
        },
        setCountryCode(code) {
            this.countryCode = code;
        },
        updateFinalFilename(filename) {
            this.finalFilename = filename;
        },
        updateBlob(blob) {
            this.blob = blob;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/_variables.scss';

.bulk-container {
    min-height: 92vh;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

/* style table deep */
::v-deep .custom-table {
    max-width: 1200px;
    width: 100%;
    margin: auto;
}

::v-deep .custom-table .table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

::v-deep .custom-table .table th,
::v-deep .custom-table .table td {
    width: 20%;
    text-align: center;
    vertical-align: middle;
    padding: 0px;
    height: 30px;
    line-height: 30px;
}

::v-deep .custom-table .table td {
    position: relative;
}

::v-deep .custom-table .table th {
    padding-left: 20px;
    padding-top: 6px;
    padding-bottom: 6px;
    background-color: white;
    border-top: 1px solid $gray-dark;
    border-bottom: 1px solid $gray-dark;
    position: sticky;
    top: 0;
    z-index: 2;
}
</style>
